import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from "moment";
import { Articles } from "../components/articles";
import { useMemo } from "react";
import { blogPosts } from "../utils/data";

const TagTemplate = ({
    data: {
        allMarkdownRemark,
        site,
        markdownRemark: post,
        productsByManufacturer,
        productsByCategory,
    },
}: any) => {
    const siteTitle = site.siteMetadata?.title || `Title`;

    const articles = useMemo(() => blogPosts.convert(allMarkdownRemark, "blog"), [allMarkdownRemark]);
    const products = useMemo(
        () => blogPosts.convert(productsByManufacturer),
        [productsByManufacturer],
    );
    const products2 = useMemo(() => blogPosts.convert(productsByCategory), [productsByCategory]);
    const products3 = useMemo(() => blogPosts.convert(allMarkdownRemark, "product"), [allMarkdownRemark]);

    const htmlWithIDs = post.html;

    const featuredImg = getImage(post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData);

    return (
        <Layout pageTitle={siteTitle} activeKey={"/"}>
            <div itemScope itemType="https://schema.org/Article">
                <article className="blog-post">
                    <header>
                        <h1 itemProp="headline">{post.frontmatter.h1}</h1>
                        <meta
                            itemProp={"datePublished"}
                            content={moment(post.frontmatter.date, "MMMM DD, YYYY").format()}
                        />
                        {featuredImg ? (
                            <GatsbyImage
                                image={featuredImg}
                                alt={post.frontmatter.title}
                                loading={"eager"}
                                itemProp={"image"}
                            />
                        ) : null}
                    </header>
                    <section dangerouslySetInnerHTML={{ __html: htmlWithIDs }} />
                </article>
                {articles.length ? <Articles articles={articles} /> : null}
                {products.length ? <Articles articles={products} /> : null}
                {products2.length ? <Articles articles={products2} /> : null}
                {products3.length ? <Articles articles={products3} /> : null}
                <footer>
                    <Bio />
                </footer>
            </div>
        </Layout>
    );
};

export const Head = ({ data: { markdownRemark: post } }: any) => {
    return (
        <Seo
            title={post.frontmatter.title}
            shortTitle
            description={post.frontmatter.description || post.excerpt}
            url={"/tag" + post.fields.slug}
            tags={post.frontmatter.utags || []}
        />
    );
};

export default TagTemplate;

export const pageQuery = graphql`
    query BlogPostsByTag($id: String!, $tag: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { tags: { eq: $tag } } }
            sort: { frontmatter: { date: DESC } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        productsByManufacturer: allMarkdownRemark(
            filter: { frontmatter: { manufacturer: { eq: $tag } } }
            sort: { frontmatter: { date: DESC } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        productsByCategory: allMarkdownRemark(
            filter: { frontmatter: { category: { eq: $tag } } }
            sort: { frontmatter: { date: DESC } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                h1
                title
                date(formatString: "MMMM DD, YYYY")
                description
                utags
                featuredImage {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            fields {
                slug
            }
        }
    }
`;
